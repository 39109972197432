body {
    background-color: #ffffff !important;
}

.ih-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    padding: 1rem 0.5rem;

    & > .hcl-row {
        flex: 1;

        & > .hcl-col {
            flex: 1;
            display: flex;
            justify-content: center;

            .ih-scrollable-content {
                height: calc(100vh - 96px);
                overflow-x: hidden !important;
                overflow-y: auto !important;
            }
        }
    }
    // Custom breakpoints for `Main Content` section
    @media only screen and (min-width: 744px) and (max-width: 1083px) {
        margin-top: 114px;
    }

    @media only screen and (min-width: 585px) and (max-width: 743px) {
        margin-top: 114px;
    }

    @media only screen and (min-width: 473px) and (max-width: 584px) {
        margin-top: 134px;
    }

    @media only screen and (min-width: 416px) and (max-width: 472px) {
        margin-top: 148px;
    }

    @media only screen and (min-width: 371px) and (max-width: 415px) {
        margin-top: 174px;
    }

    @media only screen and (min-width: 333px) and (max-width: 370px) {
        margin-top: 192px;
    }

    @media only screen and (max-width: 332px) {
        margin-top: 210px;
    }
}
